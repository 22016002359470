.customScroll {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.jobActive {
  color: #0f5132;
}

.experience-box > .row>* {
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  /* max-width: 100%; */
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  padding: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
