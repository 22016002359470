.info-wrap {
    color: rgba(255, 255, 255, 0.8);
}

.info-wrap h3 {
    color: #fff;
}

.info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
    margin-bottom: 0;
}

.info-wrap .dbox p {
    margin-bottom: 0;
}

.info-wrap .dbox p span {
    font-weight: 500;
    color: #fff;
}

.info-wrap .dbox p a {
    color: #fff;
}

.info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.info-wrap .dbox .icon span {
    font-size: 20px;
    color: #fff;
}

.info-wrap .dbox .text {
    width: calc(100% - 50px);
}


.pl-3,
.px-3 {
    padding-left: 1rem !important;
}