.candidates-listing-bg {
    padding: 210px 0px 60px 0px;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #ff9c27;
    border-color: #ff9c27;
  }
  
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .candidates-img img {
    max-width: 90px;
  }
  
  .fav-icon i {
    -webkit-text-stroke: 2px #ff9c27;
    -webkit-text-fill-color: transparent;
  }
  
  .fav-icon i:hover {
    -webkit-text-stroke: 0px #ff9c27;
    -webkit-text-fill-color: #ff9c27;
  }
  
  .candidates-list-desc {
    overflow: hidden;
  }
  
  .candidates-right-details {
    position: absolute;
    top: 0;
    right: 20px;
  }
  
  .candidates-item-desc {
    margin-left: 45px;
  }
  
  .list-grid-item {
    border: 1px solid #ececec;
    border-radius: 6px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .list-grid-item:hover {
    -webkit-box-shadow: 0 0 20px 0px rgba(47, 47, 47, 0.09);
            box-shadow: 0 0 20px 0px rgba(47, 47, 47, 0.09);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border-color: transparent;
  }
  
  .left-sidebar .card-body {
      border-bottom: 1px solid #ececec;
  }
  
  .custom-control {
      margin: 11px 20px;
  }
  
  .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
  }
  
  .pagination.job-pagination .page-link {
    border-radius: 50% !important;
    margin: 0 4px;
    height: 46px;
    width: 45px;
    line-height: 29px;
    text-align: center;
    color: #777777;
  }
  
  .page-item.active .page-link {
    background-color: #ff9c27;
    border-color: #ff9c27;
    color: #ffffff !important;
  }
  
  .f-14 {
      font-size: 14px;
  }
  
  .btn-outline {
      color: #ff9c27;
      border-color: #ff9c27;
  }
  .btn-sm {
      padding: 8px 22px;
  }

  .mr-4, .mx-4 {
    margin-right: 1.5rem!important;
}
.float-left {
    float: left!important;
}

.tooltip-container {
  position: relative;
}

.tooltip-target {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-target:hover .tooltip {
  display: block;
}
