.capitalize {
  text-transform: capitalize;
}
.img-fluid-category {
  max-width: 100%;
  height: 150px;
}


.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #FF9C27 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}


.location-banner {
  transition: ease-in-out 0.3s;
}

.location-banner:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px 0 rgb(34, 166, 153)
}
.service-banner {
  transition: ease-in-out 0.3s;
}

.service-banner:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px 0 rgb(34, 166, 153)
}
.customer-banner {
  transition: ease-in-out 0.3s;
}

.customer-banner:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px 0 rgb(34, 166, 153)
}

.popular-subcat {
  transition: ease-in-out 0.3s;
}

.popular-subcat:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px 0 rgb(34, 166, 153)
}

.recent-job {
  transition: ease-in-out 0.3s;
}

.recent-job:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px 0 rgb(34, 166, 153)
}
.cookie-consent{
  position: fixed;
  bottom: 0px;
  width: 100%;
  
  height: 45px;
  color: #fff;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background: #292929;
  z-index: 120;
  cursor: pointer;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allow-button{
    height: 20px;
  width: 104px;
  color: #fff;
  font-size: 12px;
  line-height: 10px;
  margin-left: 10px;
  border-radius: 3px;
  border: 1px solid green;
  background-color: green;
}