.footer
{
  background-color:#464849;
  padding:45px 0 20px;
  /* font-size:15px; */
  line-height:24px;
  color:#fff;
}
.footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.footer hr.small
{
  margin:20px 0
}
.footer h6
{
  color:#ff9c27;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.footer a
{
  color:#ff9c27;
}
.footer a:hover
{
  color:#009da6;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block;
  padding:5px;
}
.footer-links a
{
  color:#fff
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#009da6;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.footer .social-icons
{
  text-align:right
}
.footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .footer
  {
    padding-bottom:0
  }
  .footer .copyright-text,.footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}