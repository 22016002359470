.privacy-policy {
    padding: 10px;
}
privacy-ul {
    padding-left: 20px;
}

privacy-ul li {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    padding: 0.25rem 0;
}